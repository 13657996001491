import 'whatwg-fetch';
import 'utils/object.isInfinite.polyfill.js';

import React, { Component } from 'react';
import _get from 'lodash/get';
import Layout from 'components/layout'
import Hero from 'components/Layout/Hero';
import Bottom from 'components/Bottom/Bottom';
import BugbountyContainer from 'components/bugbounty';

class Bugbounty extends Component {
  render () {
    return (
      <Layout lang={_get(this.props, 'pageContext.lang', 'en')} page="bugbounty" pageName="bugbounty">
        <Hero />
        <div>
          <BugbountyContainer />
          <Bottom />
        </div>
      </Layout>
    );
  }
}

export default Bugbounty;
