import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import s from './Hero.module.css';
import gs from 'pages/pages.module.css';

class Hero extends React.Component {
  static propTypes = {
    header: PropTypes.string,
    subHeader: PropTypes.string,
    headerIntl: PropTypes.object,
    subHeaderIntl: PropTypes.object
  }

  render() {
    const { header, subHeader, children, headerIntl, subHeaderIntl } = this.props;

    return (
      <div className={cx(s.content)}>
          <div className={cx(gs.wrapper)}>
            {(headerIntl || header) && 
              <h1 className={cx(s.h1, gs.accent, gs.textCenter)}>
                {headerIntl || header}
              </h1>}
            {(subHeaderIntl || subHeader) && 
              <h2 className={cx(gs.textCenter, s.subheader)}>
                {subHeaderIntl || subHeader}
              </h2>}
            {children}
          </div>
      </div>
    )
  }
}

export default Hero;
