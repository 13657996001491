import React, { Component } from 'react';
import { FormattedMessage as L } from 'react-intl';
import globalStyles from 'pages/pages.module.css';
import styles from './bugbountyContainer.module.css';

class BugbountyContainer extends Component {
  render() {
    return (
      <div className={styles.root}>
        <h1><L id="bugbounty.title" /></h1>
        <p><L id="bugbounty.security.text1" /></p>
        <p>
          <L id="bugbounty.security.text2"
            values={{ hiveOsHref: (
              <a href="https://hiveos.farm" target="_blank" rel="noreferrer" className={globalStyles.link}>
                Hive OS
              </a>
            )}}
          />
        </p>
        <h2><L id="bugbounty.subtitle1" /></h2>
        <p><L id="bugbounty.programWork.text1" /></p>
        <ol>
          <li><L id="bugbounty.programWork.text2" /></li>
          <li><L id="bugbounty.programWork.text3" /></li>
          <li><L id="bugbounty.programWork.text4" /></li>
          <li><L id="bugbounty.programWork.text5" /></li>
          <li><L id="bugbounty.programWork.text6" /></li>
        </ol>
        <h2><L id="bugbounty.subtitle2" /></h2>
        <p><L id="bugbounty.vulnerabilities.text1" /></p>
        <ul>
          <li><L id="bugbounty.vulnerabilities.text2" /></li>
          <li><L id="bugbounty.vulnerabilities.text3" /></li>
          <li><L id="bugbounty.vulnerabilities.text4" /></li>
          <li><L id="bugbounty.vulnerabilities.text5" /></li>
          <li><L id="bugbounty.vulnerabilities.text6" /></li>
          <li><L id="bugbounty.vulnerabilities.text7" /></li>
          <li><L id="bugbounty.vulnerabilities.text8" /></li>
          <li><L id="bugbounty.vulnerabilities.text9" /></li>
          <li><L id="bugbounty.vulnerabilities.text10" /></li>
          <li><L id="bugbounty.vulnerabilities.text11" /></li>
          <li><L id="bugbounty.vulnerabilities.text12" /></li>
          <li><L id="bugbounty.vulnerabilities.text13" /></li>
          <li><L id="bugbounty.vulnerabilities.text14" /></li>
          <li><L id="bugbounty.vulnerabilities.text15" /></li>
        </ul>
        <h2><L id="bugbounty.subtitle3" /></h2>
        <ul>
          <li><L id="bugbounty.reward.text1" /></li>
          <li><L id="bugbounty.reward.text2" /></li>
          <li><L id="bugbounty.reward.text3" /></li>
        </ul>
        <h2><L id="bugbounty.subtitle4" /></h2>
        <ul>
          <li><L id="bugbounty.important.text1" /></li>
          <li><L id="bugbounty.important.text2" /></li>
          <li><L id="bugbounty.important.text3" /></li>
          <li><L id="bugbounty.important.text4" /></li>
          <li><L id="bugbounty.important.text5" /></li>
        </ul>
        <p><L id="bugbounty.email" values={{ email: <a href="mailto:bee@hiveos.farm" className={globalStyles.link}>bee@hiveos.farm</a> }}/></p>
      </div>
    );
  }
}

export default BugbountyContainer;
