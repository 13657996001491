import React from 'react';
import cx from 'classnames';

import globalSyles from 'pages/pages.module.css';
import s from './Community.module.css';
import tg from 'utils/images/telegram.svg'
import ds from 'utils/images/discord.svg';
import hos from 'utils/images/hos.svg';
import WL from 'constants/whiteLabel';
import Link from 'components/Link';

class Community extends React.Component {
  render() {
    if (!WL.hasCommunity()) {
      return null;
    }

    return (
      <div className={globalSyles.wrapper}>
          { false && <div className={cx(globalSyles.backgroundHeader, s.textBg)}>community</div> }
          <div className={s.itemWrapper}>
              {!!WL.getForum() && (
                <Link external noRef href={WL.getForum()} className={cx(s.item, s.web)}>
                  <img alt="forum icon" src={hos} />
                  <span className={s.itemTitle}>Forum</span>
                  { false && <h4 className={s.desc}>Community</h4> }
                </Link>
              )}
              <Link external href="https://t.me/hiveoschat_en" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>EN</span>
                  { false && <h4 className={s.desc}>Community</h4> }
              </Link>
              <Link external href="https://t.me/hiveoschat" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>РУ</span>
                  { false && <h4 className={s.desc}>Сообщество</h4> }
              </Link>
              <Link external href="https://t.me/hiveoschat_ge" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>GE</span>
              </Link>
              <Link external href="https://t.me/hiveonos" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>中文</span>
                  { false && <h4 className={s.desc}>社区</h4> }
              </Link>
              <Link external href="https://t.me/hiveoschat_jp" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>日本</span>
                 { false && <h4 className={s.desc}>コミュニティ</h4> }
              </Link>
              <Link external href="https://t.me/hiveoschat_fr" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>Fr</span>
              </Link>
              <Link external href="https://t.me/hiveoschat_italia" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>It</span>
              </Link>
              <Link external href="https://t.me/hiveoschat_es" className={s.item}>
                  <img alt="telegram icon" src={tg} />
                  <span className={s.itemTitle}>ES</span>
                  { false && <h4 className={s.desc}>Comunidad</h4> }
              </Link>
              <Link external href="https://discord.gg/CVZeZdn" className={cx(s.item, s.web)}>
                  <img alt="discord icon" src={ds} />
                  <span className={s.itemTitle}>Discord</span>
                  { false && <h4 className={s.desc}>Community Support</h4> }
              </Link>
          </div>
      </div>
    )
  }
}


export default Community;
