import React from 'react';
import PropTypes from 'prop-types';
import WL, { WL_HIVEOS } from 'constants/whiteLabel';
import Community from 'components/community';
import Footer from 'components/footer/footer';
import Social from 'components/social/social';

class Bottom extends React.Component {
  static propTypes = {
    noCommunity: PropTypes.bool
  }

  render () {
    const { noCommunity } = this.props;

    const showCommunity = noCommunity === undefined;
    return (
      <div className="bottom">
          <Footer key="footer"/>
          {showCommunity && <Community key="community"/> }
          {WL.key === WL_HIVEOS ? <Social key="social" hideMobile={true} /> : null}
      </div>
    )
  }
}

export default Bottom;
